import moment from 'moment'
import { ContentTypes } from 'types/HwContentTypes'

export const isIe10 = (): boolean => {
  return 'onpropertychange' in document && !!window.matchMedia
}

export function openUrlNewWindow(pdfUrl: string) {
  window.open(pdfUrl)
}

export function getAlgoliaLanguagePackageId(languagePackageAttribute: string): string {
  const id = languagePackageAttribute.split('.')
  if (id.length <= 2) {
    return ''
  } else {
    return id[2]
  }
}

export const actionKeys: { [key: string]: number } = {
  Backspace: 8,
  Tab: 9,
  Enter: 13,
  Shift: 16,
  Esc: 27,
  Space: 32,
  PageUp: 33,
  PageDown: 34,
  End: 35,
  Home: 36,
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
  Delete: 46,
}

export const keyCodes: { [key: number]: number } = {
  8: actionKeys.Backspace,
  9: actionKeys.Tab,
  13: actionKeys.Enter,
  16: actionKeys.Shift,
  27: actionKeys.Esc,
  32: actionKeys.Space,
  33: actionKeys.PageUp,
  34: actionKeys.PageDown,
  35: actionKeys.End,
  36: actionKeys.Home,
  37: actionKeys.ArrowLeft,
  38: actionKeys.ArrowUp,
  39: actionKeys.ArrowRight,
  40: actionKeys.ArrowDown,
  46: actionKeys.Delete,
}

export const isEditableContentType = (contentType: string): boolean => {
  return (
    contentType !== ContentTypes.VIDEO &&
    contentType !== ContentTypes.CDC &&
    contentType !== ContentTypes.FDB &&
    contentType !== ContentTypes.ASHP
  )
}

export const testElementIsOutsideListBox = (
  target: HTMLElement | Element | null,
  wrapperId: string
): boolean => {
  const $wrapper = document.getElementById(wrapperId)
  if ($wrapper) {
    let elementIsInsideWrapper = $wrapper && $wrapper.contains(target)
    return !elementIsInsideWrapper
  }
  return false
}

export const handleGlobalEscapeKey = (
  e: any,
  wrapperId: string,
  callback: (cbValue: boolean) => void,
  cbValue: boolean
) => {
  var key: number = e.which || e.keyCode
  const keyCode = keyCodes[key]
  if (keyCode === actionKeys.Esc) {
    callback(cbValue)
  } else if (
    document.activeElement?.tagName !== 'BODY' &&
    testElementIsOutsideListBox(document.activeElement, wrapperId)
  ) {
    callback(cbValue)
  }
}

const smallWordsToIgnore = [
  'a',
  'an',
  'the',
  'at',
  'around',
  'by',
  'after',
  'along',
  'for',
  'from',
  'of',
  'on',
  'to',
  'with',
  'without',
  'for',
  'and',
  'nor',
  'but',
  'or',
  'yet',
]
export const capitalize = (toCapitalize?: string, ignoreSmallWords = true): string => {
  if (toCapitalize) {
    const words = (toCapitalize || '').split(' ')
    for (let i = 0; i < words.length; i++) {
      if (
        words[i]?.[0] &&
        (!(ignoreSmallWords && smallWordsToIgnore.includes(words[i])) || i === 0)
      ) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
      }
    }
    return words.join(' ')
  }
  return ''
}

const currentYear = new Date().getFullYear()
const defaultMin = new Date(currentYear - 2, 0, 1)
const defaultMax = new Date(currentYear + 2, 11, 31)

export const dateIsValid = (
  dateCandidate: Date | string | undefined,
  isRequired = false,
  min = defaultMin,
  max = defaultMax
) => {
  let isValid = false

  if (dateCandidate) {
    isValid = true
    const date = dateCandidate instanceof Date ? dateCandidate : new Date(dateCandidate)
    if (date) {
      if (date < min || date > max) {
        isValid = false
      }
    } else {
      isValid = false
    }
  } else {
    isValid = !isRequired
  }
  return isValid
}

export const isDateInThePast = (date: Date | string | undefined) => {
  const normalizedDate = getValidDate(date)
  if (normalizedDate) {
    const now = new Date()
    return normalizedDate < now
  }
  return false
}

export function getCurrentTimestamp(): number {
  return moment.now()
}

export function deriveElapsedMillisecondsString(initialTimestamp: number): number {
  return moment().diff(initialTimestamp)
}

export function getUtcDatestampString(): string {
  return moment.utc().toISOString()
}

export function getTimestampMonthFirst(dt: Date): string {
  return moment(dt).format('MM-DD-YYYY')
}

export function getTimestampMonthAbbr(dt: Date): string {
  return moment(dt).format('DD-MMM-YYYY')
}

export function calculatePatientAge(birthDate: Date | string | undefined): string | undefined {
  const normalizedDate = getValidDate(birthDate)
  if (normalizedDate) {
    return moment().diff(normalizedDate, 'years').toString()
  }

  return undefined
}

export const getAge = (
  birthDate: Date | string | undefined,
  dateToCheck: Date | string | undefined = new Date()
) => {
  const normalizedEndDate = getValidDate(dateToCheck)
  const normalizedDate = getValidDate(birthDate)
  if (normalizedDate) {
    const timeDifference =
      (normalizedEndDate?.getTime() || new Date().getTime()) - normalizedDate.getTime()
    const ageInDays = Math.floor(timeDifference / (1000 * 3600 * 24))
    const ageInMonths = Math.floor(timeDifference / (1000 * 3600 * 24 * 30.417))
    const ageInYears = Math.floor(timeDifference / (1000 * 3600 * 24 * 365))
    if (ageInDays >= 0) {
      if (ageInDays < 31) {
        return `${ageInDays} day` + (ageInDays === 1 ? '' : 's')
      } else if (ageInMonths <= 23) {
        return `${ageInMonths} month` + (ageInMonths === 1 ? '' : 's')
      }
      return `${ageInYears} year` + (ageInYears === 1 ? '' : 's')
    }
  }
  return 'Invalid age'
}

export const getValidDate = (
  date?: Date | string | undefined,
  fallback: Date | null | undefined = null
): Date | null => {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return date
  }

  let normalizedDate
  if (typeof date === 'string') {
    if (!!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return parseDate(date)
    }
    if (!!date.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
      return parseDate(date, 'yyyy/mm/dd')
    }
    if (!date.match(/\d{4}-\d{2}-\d{2}T\d{2}/)) {
      // Replacing dashes with slashes
      // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off#answer-31732581
      date = date.replace(/-/g, '/')
    }
    normalizedDate = new Date(date)
  }

  if (!normalizedDate || isNaN(normalizedDate.getTime())) {
    return fallback
  }
  return normalizedDate
}

const parseDate = (input: string, format = 'yyyy-mm-dd') => {
  const parts: RegExpMatchArray | null = input.match(/(\d+)/g)
  let i = 0
  const formatParts: { [key: string]: number } = {}
  format.replace(/(yyyy|dd|mm)/g, (part: string): string => {
    formatParts[part] = i++
    return part
  })

  const yearString: string | undefined = parts?.[formatParts['yyyy']]
  let year: number = 0
  if (yearString?.startsWith('00')) {
    // Warning: this will need to change on 01/01/2100
    const currentCentury = 2000
    const yearModifier = 3
    const twoDigitYear = currentYear - currentCentury + yearModifier
    var yearValue = parseInt(yearString, 10)

    if (yearValue <= twoDigitYear) {
      year = yearValue + currentCentury
    } else {
      year = yearValue + currentCentury - 100
    }
  } else if (yearString) {
    year = parseInt(yearString, 10)
  }

  return new Date(
    year,
    parseInt(parts?.[formatParts['mm']] || '0', 10) - 1,
    parseInt(parts?.[formatParts['dd']] || '0', 10)
  )
}

export const formatDateForDateFieldValue = (
  dateValue?: Date | string | undefined,
  leadingYearZeros = true
) => {
  const normalized = getValidDate(dateValue)
  if (normalized) {
    let year: string | number = normalized.getUTCFullYear()
    const month = normalized.getUTCMonth()
    const date = normalized.getUTCDate()
    if (isNaN(year) || isNaN(month) || isNaN(date)) {
      return ''
    }
    year = leadingYearZeros ? ('000' + year).slice(-4) : year
    return `${year}-${('0' + (month + 1)).slice(-2)}-${('0' + date).slice(-2)}`
  }
  return ''
}

export const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0
}
