import env from '@beam-australia/react-env'
import { CKEditor, CKEditorConfig, CKEditorEventPayload } from 'ckeditor4-react'
import { FC } from 'react'

const CDN_URL = env('CKEDITOR_CDN')
const LICENSE_KEY = env('CKEDITOR_LICENSE_KEY')

export const CK_EDITOR_TEST_ID = 'ck-editor'

type Props = {
  content: string
  setEditedContent: (content: string) => void
}

const config: CKEditorConfig = {
  coreStyles_bold: { element: 'b', overrides: 'strong' },
  coreStyles_italic: { element: 'i', overrides: 'em' },
  disableNativeSpellChecker: false,
  autoGrow_onStartup: true,
  resizeEnabled: false,
  autoParagraph: false,
  toolbar: [
    { name: 'basicstyles', items: ['Bold', 'Italic'] },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
  ],
  title: 'Healthwise Content',
  // TODO: this is very dangerous!
  allowedContent: true,
  disallowedContent: 'a[href]',
  height: '400px',
  width: '100%',
  contentsCss: 'CKEditor/CKEditor.css',
  licenseKey: LICENSE_KEY,
  versionCheck: false,
}

const CKContentEditor: FC<Props> = ({ content, setEditedContent }) => {
  const handleChange = (payload: CKEditorEventPayload<'change'>) => {
    const { editor } = payload
    const data = editor?.getData()?.trim()
    setEditedContent(data)
  }

  return (
    <div data-testid={CK_EDITOR_TEST_ID}>
      {LICENSE_KEY && (
        <CKEditor
          type='classic'
          name='hw-content-editor'
          config={config}
          onChange={handleChange}
          initData={content}
          editorUrl={CDN_URL}
        />
      )}
    </div>
  )
}

export default CKContentEditor
